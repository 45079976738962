<template>
  <div>
    <b-card>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="20"
        />
        <h4 class="mb-0 ml-50">
          Guards Create
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <validation-observer
        ref="guardsCreate"
      >
        <b-form class="mt-1" @submit.prevent="submitUser">
          <b-row>
            <!-- username -->
            <b-col>
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="userDataInfo.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Username"
                  />
                  <!-- <InputPasswordToggle className='mb-2' label='Password' htmlFor='basic-default-password' /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- password -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <!-- <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="passValue"
                      type="password"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group> -->
                  <b-form-input
                    id="password"
                    v-model="userDataInfo.password"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- nik -->
            <b-col
              cols="12"
              md="6"
              lg="4">
              <b-form-group
                label="Code"
                label-for="Code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CODE"
                  rules="required"
                >
                  <b-form-input
                    id="CODE"
                    v-model="userDataInfo.code"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Input Code"
                  />
                  <!-- <InputPasswordToggle className='mb-2' label='Password' htmlFor='basic-default-password' /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Name -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="userDataInfo.name"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                id="email"
                v-model="userDataInfo.email"
                type="email"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Mobile -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="No Handphone"
                label-for="mobile"
              >
                <b-form-input
                  id="mobile"
                  v-model="userDataInfo.mobile"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="userDataInfo.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  value=""
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Site"
                label-for="site"
              >
                <v-select
                  v-model="userDataInfo.area"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areaOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="isSubmitted"
                @click=submitUser
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import guardStoreModule from '@/views/apps/settings/guards/guardStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-guards'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, guardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    const isSubmitted = false

    const userDataInfo = ref({
      username: '',
      password: '',
      code: '',
      name: '',
      email: '',
      mobile: '',
      status: '',
      area: '',
    })

    const statusOptions = [
      { label: 'Enabled', value: 1 },
      { label: 'Disabled', value: 0 },
    ]

    const areaOptions = ref([])

    const areaOptions1 = () => {
      store
        .dispatch('app-guards/fetchArea')
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          // roleOptions = response.data.data
          areaOptions.value = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching role list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    areaOptions1()
    return {
      userDataInfo,
      statusOptions,
      areaOptions,
      required,
      isSubmitted,
    }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    submitUser() {
      this.$refs.guardsCreate.validate().then(success => {
        if (success) {
          this.isSubmitted = true
          axios
            .post(`${this.$store.state.customConfig.baseURL}/web/guard/create`, {
              apikey: this.$store.state.customConfig.token,
              username: this.userDataInfo.username,
              password: this.userDataInfo.password,
              code: this.userDataInfo.code,
              name: this.userDataInfo.name,
              email: this.userDataInfo.email,
              phone_number: this.userDataInfo.mobile,
              status: this.userDataInfo.status,
              area_id: this.userDataInfo.area,
            }, {
              headers: { 'Access-Control-Allow-Origin': '*' },
            })
            .then(responseUser => {
              console.log(responseUser)
              this.$router.replace('/settings/guards')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Create User submitted',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully submitted a user',
                    },
                  })
                })
            })
            .catch(error => {
              if (error) {
                this.isSubmitted = false
                alert("Create Guard Gagal")
              }
              // console.log(error)
              // this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
